<template>
  <div class="ev-containter" v-if="isLoading">
    <h3 style="color: green">Loading . . .</h3>
  </div>
  <div class="ev-containter" v-else>
    <h3 style="color: green" v-if="verified">Your Email verified successfully</h3>
    <h3 style="color: red" v-else>Email verification failed! try again later.</h3>
    <router-link :to="{ name: 'profile' }" class="back-btn">Back to profile</router-link>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      verified: false,
      isLoading: false,
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    ...mapActions(["getEmailVerify", "getUser"]),
    checkQuery() {
      let hash = this.$route.query.token;
      if (hash) {
        this.isLoading = true;
        this.getEmailVerify(hash)
          .then((res) => {
            if (res) {
              this.verified = res.data.result;
              this.getUser();
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ev-containter {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .back-btn {
    border: 1px solid var(--color7);
    padding: 8px 25px;
    border-radius: 5px;
    color: var(--color7);
    font-size: 12px;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      background: var(--color7);
      color: black;
    }
  }
}
</style>
